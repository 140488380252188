import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { styles } from 'app/theme'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

export interface Props {
  cta?: ButtonProps
  description?: string
  isExpanded?: boolean
  label?: string
  icon?: string
}

export const Item = memo(function Item({
  cta,
  description,
  label,
  icon,
}: Props) {
  if (!label) {
    return null
  }

  return (
    <Container activeClassName="active">
      <Head>
        <Title className="title">
          {label}
          {icon ? <Icon src={icon} alt={label} width={53} height={53} /> : null}
          <Arrow
            src="/icon_arrow_accordion.svg"
            alt="expand"
            className="arrow"
            width="27"
            height="27"
          />
        </Title>
      </Head>

      {description ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Description
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                    {cta ? (
                      <StyledButton {...cta} icon="/icon-map-button.svg" />
                    ) : null}
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)`
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.primaryDark};
`

const Head = styled(AccordionItemHeading)`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`

const Title = styled(AccordionItemButton)`
  ${styles.h3}
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3.125rem 0 2.5rem;
  transition: padding 0.3s ease-in-out;

  @media (max-width: 1199px) {
    padding: 2.125rem 0 2rem;
    font-size: 1.4375rem;
  }
`

const Icon = styled.img`
  margin-left: 1.75rem;
  margin-bottom: -0.375rem;

  @media (max-width: 1199px) {
    width: 2.6875rem;
    height: 2.6875rem;
    margin-bottom: 0;
  }
`

const Arrow = styled.img`
  margin-right: 0.3125rem;
  margin-left: auto;

  @media (max-width: 1199px) {
    width: 1.375rem;
    height: 1.375rem;
  }
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  overflow: hidden;
`

const Description = styled.div`
  ${styles.description}
  margin-bottom: 3.75rem;

  @media (max-width: 1199px) {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }

  @supports (initial-letter: 5) or (-webkit-initial-letter: 5) {
    &::first-letter {
      -webkit-initial-letter: unset;
      initial-letter: unset;
      padding: 0;
      margin-top: 0;
    }
  }

  ul {
    list-style: inside;
  }

  a {
    text-decoration: underline;
  }
`

const StyledButton = styled(Button)`
  margin-bottom: 3.75rem;

  @media (max-width: 1199px) {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }
`
