import styled from '@emotion/styled'
import { styles } from 'app/theme'
import React, { memo } from 'react'
import { Accordion as ReactAccordion } from 'react-accessible-accordion'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const Accordion = memo(function Accordion({ items, title }: Props) {
  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {items ? (
          <Items>
            <ReactAccordion allowZeroExpanded allowMultipleExpanded>
              {items.map((item: any, index) => (
                <Item key={index} {...item} />
              ))}
            </ReactAccordion>
          </Items>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
  }

  @media (max-width: 550px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 68.125rem;
  margin: 0 auto;
  padding: 10.3125rem 6.875rem 9.375rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};
  background-image: url('/wrapper_bg.png');
  background-repeat: no-repeat;
  background-position: center 8.125rem;

  @media (max-width: 1199px) {
    max-width: 100%;
    padding: 8.3125rem 2.5rem 5.375rem;
  }

  @media (max-width: 550px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-size: 10.875rem 8.25rem;
    background-position: center 3.125rem;
  }
`

const Title = styled.div`
  ${styles.label}
  position: absolute;
  top: 8.125rem;
  left: -6.875rem;

  @media (max-width: 1199px) {
    top: 6.125rem;
    left: 2.5rem;
  }

  @media (max-width: 550px) {
    left: 1.25rem;
  }
`

const Items = styled.div`
  .accordion {
    > div {
      transition: 0.5s ease-in-out;

      svg {
        fill: ${({ theme }) => theme.colors.variants.primaryDark};
      }

      &.active {
        border-bottom: 0.0625rem solid
          ${({ theme }) => theme.colors.variants.primaryDark};

        .title {
          padding-bottom: 1.25rem;
        }

        .arrow {
          transform: rotate(180deg);
        }
      }
    }
  }
`
